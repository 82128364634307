<template>
  <div id="faqs" class="container-fluid bg-gray-dark py-5">
    <div class="container">
      <div class="row mb-4">
        <div class="col-12 col-md-10 offset-md-1 text-center">
          <h2 class="title font-family-krona">Frequently Asked</h2>
          <p class="subtitle text-gray text-uppercase">Check the most frequently asked questions that our users ask us and learn more about DXboard</p>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-7">
          <div id="accordion">
            <template v-for="(question,index) in questions" :key="index">
              <div :id="'faq' + index" class="border-bottom">
                <h5 class="mb-0">
                  <button class="w-100 btn collapsed text-gray text-left py-3 d-flex align-items-center no-focus justify-content-between" data-toggle="collapse" :data-target="'#collapse' + index" aria-expanded="false" :aria-controls="'#collapse' + index">
                    <div class="">{{ question.question }}</div>
                    <div class="font-size-150"><font-awesome-icon :icon="['fas', 'chevron-down']" /></div>
                  </button>
                </h5>
              </div>
              <div :id="'collapse' + index" class="collapse" :aria-labelledby="'faq' + index" data-parent="#accordion">
                <div class="p-3 text-gray">
                  {{ question.answer }}
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="col-12 col-md-5 text-center">
          <img src ="@/assets/bg-faqs.png" alt="faqs" height="275"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'faqs',
  data() {
    return {
      questions: [
        {
          question: "What cryptocurrencies can I add to my portfolio?",
          answer: "Through the crypto importer search engine you can search for the symbol or contract address of the crypto you want to add. DXboard uses different data sources from multiple aggregators to obtain the data of the crypto you are looking for and its value in USD."
        },
        {
          question: "Do I need to deposit my cryptos in Dboard to be able to view them?",
          answer: "Of course not! From DXboard you can add your cryptos manually or automatically by synchronizing directly from your wallet. The only thing you will have to do is indicate the symbol or contract to import and the amount you have. Once that is done, you will be able to see the total value of your position in that symbol and the global balance of all the cryptos you have added, but your cryptos will still be in your wallets."
        },
        {
          question: "What are the packs and what are they for?",
          answer: "Have you ever invested an amount of money in a crypto and forgotten what your initial investment was? Or have you wanted to invest an amount of money in various currencies but you don't know what the performance of that investment package has been? The Packs allow you to define an investment budget and how you have distributed it so that you can track it over long periods of time. You will be able to see at all times what the current status of your investment is, what the % profit or loss is and how each of your crypto assets is doing."
        },
        {
          question: "What wallets can I use to automatically synchronize my cryptos?",
          answer: "At the moment DXboard only supports metamask but we will gradually add support to other types of wallets."
        }
      ]
    };
  },
  created() {
    this.$emit('widgetLoaded');
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  #faqs{
    h2{
      font-size:28px;
    }
  }
</style>
